import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_PROD_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var basePre = 'system';
var pre = 'menu';
var meta = {
  auth: true,
  closable: true
};
export default [{
  path: "/".concat(basePre),
  title: '系统管理',
  name: "".concat(basePre),
  header: "".concat(basePre),
  // 生成的时候就是正确的 固定的
  icon: 'md-speedometer',
  redirect: {
    name: "".concat(pre, "-center") // 动态遍历取第一子级的第一个路由name
  },
  meta: meta,
  component: 'layouts/basic-layout',
  children: [{
    path: "/".concat(basePre, "/").concat(pre, "/center"),
    title: '菜单管理',
    name: "".concat(pre, "-center"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '菜单管理'
    }),
    component: "pages/".concat(basePre, "/").concat(pre)
  }, {
    path: "/".concat(basePre, "/").concat(pre, "/permission-tenant"),
    title: '品牌权限管理',
    name: "".concat(pre, "-permission-tenant"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '品牌权限管理'
    }),
    component: "pages/".concat(basePre, "/permission/tenant.vue")
  }, {
    path: "/".concat(basePre, "/").concat(pre, "/permission-role"),
    title: '角色权限管理',
    name: "".concat(pre, "-permission-role"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '角色权限管理'
    }),
    component: "pages/".concat(basePre, "/permission/role.vue")
  }]
}];