import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.for-each";
import BasicLayout from '@/layouts/basic-layout';

// const meta = {
//     auth: true
// };

var files = require.context('./modules', true, /\.js$/);
var modules = {};
files.keys().forEach(function (key) {
  var path = key.replace(/(\.\/|\.js)/g, '');
  try {
    path = path.split('/').pop();
  } catch (error) {}
  modules[path] = files(key).default;
});

/**
 * 在主框架内显示
 */

var frameIn = [{
  path: '/',
  redirect: {
    // 启动页
    name: 'login'
  },
  component: BasicLayout,
  children: [{
    path: 'log',
    name: 'log',
    meta: {
      title: '前端日志',
      auth: true
    },
    component: function component() {
      return import('@/pages/system/log');
    }
  },
  // 设置
  // {
  //     path: '/setting/account',
  //     name: 'system-set',
  //     meta: {
  //         auth: true,
  //         title: '设置'
  //     },
  //     component: () => import('@/pages/system/set')
  // },
  // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: {
      beforeRouteEnter: function beforeRouteEnter(to, from, next) {
        next(function (instance) {
          return instance.$router.replace(from.fullPath);
        });
      },
      render: function render(h) {
        return h();
      }
    }
  },
  // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: {
      beforeRouteEnter: function beforeRouteEnter(to, from, next) {
        next(function (instance) {
          return instance.$router.replace(JSON.parse(from.params.route));
        });
      },
      render: function render(h) {
        return h();
      }
    }
  }]
}];

/**
 * 在主框架之外显示
 */

var frameOut = [
// 登录
{
  path: '/login',
  name: 'login',
  meta: {
    title: '$t:page.login.title'
  },
  component: function component() {
    return import('@/pages/account/login');
  }
},
// 三方登录，页面重定向
{
  path: '/login/three',
  name: 'three-login',
  meta: {
    auth: false,
    title: '登录'
  },
  component: function component() {
    return import('@/pages/account/login/three');
  }
},
// 注册
{
  path: '/register',
  name: 'register',
  meta: {
    title: '$t:page.register.title'
  },
  component: function component() {
    return import('@/pages/account/register');
  }
},
// 注册结果
{
  path: '/register/result',
  name: 'register-result',
  meta: {
    auth: true,
    title: '注册结果'
  },
  component: function component() {
    return import('@/pages/account/register/result');
  }
}];

/**
 * 错误页面
 */

var errorPage = [{
  path: '/403',
  name: '403',
  meta: {
    title: '403'
  },
  component: function component() {
    return import('@/pages/system/error/403');
  }
}, {
  path: '/500',
  name: '500',
  meta: {
    title: '500'
  },
  component: function component() {
    return import('@/pages/system/error/500');
  }
}, {
  path: '/404',
  name: '404',
  meta: {
    title: '404'
  },
  component: function component() {
    return import('@/pages/system/error/404');
  }
}, {
  path: '/error',
  name: 'error',
  meta: {
    title: 'error'
  },
  component: function component() {
    return import('@/pages/system/result/error');
  }
}, {
  path: '/success',
  name: 'success',
  meta: {
    title: 'success'
  },
  component: function component() {
    return import('@/pages/system/result/success');
  }
}];

// 导出需要显示菜单的
export var frameInRoutes = frameIn;

// 导出不在菜单中的页面
export var otherRoutes = modules;

// 重新组织后导出
export default [].concat(frameIn, frameOut, errorPage);