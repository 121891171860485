export default {
  namespaced: true,
  state: {
    couponTemplateList: [] // 券模板类型
  },
  getters: {},
  mutations: {
    /**
     * @description 设置券模板类型
     */
    setCouponTemplateList: function setCouponTemplateList(state, list) {
      state.couponTemplateList = list;
    }
  },
  actions: {}
};