var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("Button", {
    class: ["i-custom-btn", _vm.className],
    attrs: {
      size: _vm.size
    },
    on: {
      click: _vm.handleClick
    }
  }, [_c("span", {
    ref: "customIcon",
    class: ["i-custom-btn-icon", _vm.iconClass],
    style: {
      backgroundImage: "url(".concat(_vm.backgroundImage, ")")
    }
  }), _vm._v("\n    " + _vm._s(_vm.title) + "\n")]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };