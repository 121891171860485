var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.title ? _c("PageHeader", {
    staticClass: "i-page-header",
    attrs: {
      title: _vm.title,
      "breadcrumb-list": _vm.breadcrumbList
    }
  }) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };