import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_PROD_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var basePre = 'member-center';
var pre = 'membership';
var meta = {
  auth: true,
  closable: true,
  showMenu: false,
  activePath: "/".concat(basePre, "/").concat(pre, "/membership-card")
};
// const activePathIntegral = `/${basePre}/${pre}/integral`

export default [{
  path: "/".concat(basePre, "/").concat(pre, "/membership-add"),
  title: '新增会员卡方案',
  name: "".concat(pre, "-add"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '新增会员卡方案'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/membership-detail")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/membership-edit"),
  title: '编辑会员卡方案',
  name: "".concat(pre, "-edit"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '编辑会员卡方案'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/membership-detail")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/membership-detail"),
  title: '会员卡方案详情',
  name: "".concat(pre, "-detail"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '会员卡方案详情'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/membership-detail")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/membership-grade/:cardTypeId"),
  title: '会员等级',
  name: "".concat(pre, "-grade"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/membership-grade"),
    title: '会员等级'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/membership-grade")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/membership-setting/:cardTypeId/:type"),
  title: '会员等级规则设置',
  name: "".concat(pre, "-setting"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/membership-grade"),
    title: '会员等级规则设置'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/membership-setting")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/membership-growup-detail/:cardTypeId/:type/:id?"),
  title: '成长值设置',
  name: "".concat(pre, "-growup-detail"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/membership-grade"),
    title: '成长值设置'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/membership-growup-detail")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/membership-level-detail/:cardTypeId/:type/:id?"),
  title: '等级规则设置',
  name: "".concat(pre, "-level-detail"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/membership-grade"),
    title: '等级规则设置'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/membership-level-detail")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/membership-interests-grade/:cardTypeId/:type/:id?"),
  title: '升级权益设置',
  name: "".concat(pre, "-interests-grade"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/membership-grade"),
    title: '升级权益设置'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/membership-interests-grade")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/membership-interests-sales/:cardTypeId/:type/:id?"),
  title: '消费权益设置',
  name: "".concat(pre, "-interests-sales"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/membership-grade"),
    title: '消费权益设置'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/membership-interests-sales")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/membership-interests-birthday/:cardTypeId/:type/:id?"),
  title: '生日权益设置',
  name: "".concat(pre, "-interests-birthday"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/membership-grade"),
    title: '生日权益设置'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/membership-interests-birthday")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/membership-interests-memberday/:cardTypeId/:type/:id?"),
  title: '会员日权益设置',
  name: "".concat(pre, "-interests-memberday"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/membership-grade"),
    title: '会员日权益设置'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/membership-interests-memberday")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/integral/:cardTypeId"),
  title: '积分体系',
  name: "".concat(pre, "-integral"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/integral"),
    title: '积分体系'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/integral")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/integral/setting/:cardTypeId/:type/:editType/:id?"),
  title: '积分体系设置',
  name: "".concat(pre, "-integral-setting"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/integral"),
    title: '积分体系设置'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/integral-setting")
},
// {
//     path: `/${basePre}/${pre}/integral/setting/:cardTypeId`,
//     title: '获取设置',
//     name: `${pre}-integral-setting-get`,
//     meta: {
//         ...meta,
//         activePath: activePathIntegral,
//         title: '获取设置'
//     },
//     component: `pages/${basePre}/${pre}/integral-setting`
// },
// {
//     path: `/${basePre}/${pre}/integral/setting/:cardTypeId`,
//     title: '积分体系获取设置',
//     name: `${pre}-integral-setting-rule`,
//     meta: {
//         ...meta,
//         activePath: activePathIntegral,
//         title: '积分体系获取设置'
//     },
//     component: `pages/${basePre}/${pre}/integral-setting`
// },
{
  path: "/".concat(basePre, "/").concat(pre, "/integral/settings-list/:cardTypeId/:type"),
  title: '积分体系获取设置',
  name: "integral-settings-list",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/integral"),
    title: '积分体系获取设置'
  }),
  component: 'pages/member-center/membership/integral-setting/settings-list.vue'
}, {
  path: "/".concat(basePre, "/").concat(pre, "/membership-operate/operate-list/:type"),
  title: '会员卡操作列表',
  name: "membership-operate-list",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/membership-operate"),
    title: '会员卡操作列表'
  }),
  component: 'pages/member-center/membership/membership-operate/operate-list.vue'
}, {
  path: "/".concat(basePre, "/").concat(pre, "/membership-operate/operate-list/:type/detail"),
  title: '会员卡操作详情',
  name: "membership-operate-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/membership-operate"),
    title: '会员卡操作详情'
  }),
  component: 'pages/member-center/membership/membership-operate/operate-detail.vue'
}];