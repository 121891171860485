var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("Button", {
    attrs: {
      type: "text",
      icon: "ios-copy-outline",
      disabled: !_vm.value
    },
    on: {
      click: _vm.handleCopy
    }
  }, [_vm._v("复制")]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };