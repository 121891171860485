import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.date.to-string";
import "core-js/modules/es6.number.constructor";
export default {
  name: '',
  components: {},
  props: {
    value: {
      type: [String, Number],
      default: ''
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    dataName: function dataName() {
      return '';
    }
  },
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    handleCopy: function handleCopy() {
      if (this.value) {
        this.$Copy({
          text: this.value.toString()
        });
      }
    }
  }
};