/**
 * 通用错误码
 */
var ERROR_CODE = {
  '0': '未知异常',
  '200': '请求成功',
  '403': '权限拒绝',
  '404': '未找到相关信息',
  '10000': '参数错误',
  '11000': 'Token错误',
  '11001': '令牌过期错误',
  '11002': '匹配令牌错误',
  '12000': '限制访问',
  '12001': '访问频次过高',
  '12002': '账号异常限制访问',
  '13000': '服务内部异常',
  '20000': '数据不存在',
  '20001': '会员数据不存在',
  '20002': '券信息不存在',
  '21000': '非匹配数据',
  '21001': '用户数据不匹配',
  '21002': '券数据不匹配',
  '22000': '回滚异常'
};
export default ERROR_CODE;