import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.map";
import adminMock from "./admin-mock";
var req = function req(context) {
  return context.keys().map(context);
};
var options = req(require.context('./api/', true, /\.js$/)).filter(function (e) {
  return e.default;
}).map(function (e) {
  return e.default;
});
options.forEach(function (option) {
  adminMock.load(option);
});