var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "i-card-title"
  }, [_c("div", {
    staticClass: "i-card-title-icon"
  }), _c("div", {
    staticClass: "i-card-title-text"
  }, [_vm._v("\n        " + _vm._s(_vm.title) + "\n    ")]), _vm._t("default")], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };