export default {
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    size: {
      type: String,
      required: false,
      default: 'large'
    },
    className: {
      type: String,
      required: false,
      default: ''
    },
    iconClass: {
      type: String,
      required: false,
      default: ''
    },
    iconName: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      isHovering: false,
      backgroundImage: '',
      baseImgPath: 'https://image.streffy.com/StreffyAdminImage/StreffyMiddleOffice/image/'
    };
  },
  created: function created() {
    this.backgroundImage = this.baseImgPath + this.iconName + '.png';
  },
  mounted: function mounted() {
    this.$el.addEventListener('mouseenter', this.handleMouseEnter);
    this.$el.addEventListener('mouseleave', this.handleMouseLeave);
  },
  beforeDestroy: function beforeDestroy() {
    this.$el.removeEventListener('mouseenter', this.handleMouseEnter);
    this.$el.removeEventListener('mouseleave', this.handleMouseLeave);
  },
  methods: {
    handleMouseEnter: function handleMouseEnter() {
      this.backgroundImage = this.baseImgPath + this.iconName + '_pre.png';
    },
    handleMouseLeave: function handleMouseLeave() {
      this.backgroundImage = this.baseImgPath + this.iconName + '.png';
    },
    handleClick: function handleClick(e) {
      this.$emit('handle-click', e);
    }
  }
};