var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("i-link", {
    staticClass: "i-layout-header-logo",
    class: {
      "i-layout-header-logo-stick": !_vm.isMobile,
      "i-layout-header-logo-light": _vm.headerTheme === "light"
    },
    attrs: {
      to: "/"
    }
  }, [_c("img", {
    staticClass: "i-layout-header-logo-img",
    attrs: {
      src: "https://image.streffy.com/StreffyAdminImage/StreffyMiddleOffice/log/logo-text.png"
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };