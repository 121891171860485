var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("Upload", {
    staticClass: "i-upload",
    attrs: {
      multiple: "",
      action: _vm.action
    }
  }, [_c("Button", {
    attrs: {
      icon: "ios-cloud-upload-outline",
      size: "large"
    }
  }, [_vm._v("\n        导入\n    ")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };