import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/es6.array.map";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.split";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_PROD_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.regexp.match";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import Mock from 'mockjs';
import qs from 'qs';
import withCredentials from "./patch/withCredentials";
Mock.XHR.prototype.send = function () {
  var _send = Mock.XHR.prototype.send;
  return function () {
    if (!this.match) {
      this.custom.xhr.responseType = this.responseType || '';
      this.custom.xhr.timeout = this.timeout || 0;
      this.custom.xhr.withCredentials = this.withCredentials || false;
      this.custom.xhr.onabort = this.onabort || null;
      this.custom.xhr.onerror = this.onerror || null;
      this.custom.xhr.onload = this.onload || null;
      this.custom.xhr.onloadend = this.onloadend || null;
      this.custom.xhr.onloadstart = this.onloadstart || null;
      this.custom.xhr.onprogress = this.onprogress || null;
      this.custom.xhr.onreadystatechange = this.onreadystatechange || null;
      this.custom.xhr.ontimeout = this.ontimeout || null;
    }
    return _send.apply(this, arguments);
  };
}();
/* 补丁 */
withCredentials(Mock);

/* Mock 默认配置 */
Mock.setup({
  timeout: '200-300'
});

/* 扩展 [生成器] */
var Generator = function Generator(prop, template) {
  var obj = {};
  obj[prop] = [template];
  return Mock.mock(obj);
};

/* 扩展 [循环] */
var Repeat = function Repeat(num, itemTemplate) {
  return Generator("data|".concat(num), itemTemplate).data;
};
var CustomExtends = {
  Generator: Generator,
  Repeat: Repeat,
  Mock: Mock,
  Random: Mock.Random
};
var extend = function extend(prop, value) {
  CustomExtends[prop] = value;
};

/* 装配配置组 */
var wired = function wired(_ref) {
  var url = _ref.url,
    type = _ref.type,
    body = _ref.body;
  return _objectSpread({
    method: type,
    params: qs.parse(url.split('?').length > 1 ? url.split('?')[1] : ''),
    body: JSON.parse(body),
    url: qs.parse(url.split('?')[0])
  }, CustomExtends);
};
var setup = function setup(path, method, handle) {
  Mock.mock(RegExp(path), method, typeof handle === 'function' ? function (o) {
    return handle(wired(o));
  } : handle);
};
var load = function load(collection) {
  collection.map(function (_ref2) {
    var path = _ref2.path,
      method = _ref2.method,
      handle = _ref2.handle;
    if (method === '*') {
      method = ['get', 'head', 'post', 'put', 'delete', 'connect', 'options', 'trace', 'patch'];
    }
    if (typeof method === 'string' && method.indexOf('|') > -1) method = method.split('|');
    if (method instanceof Array) {
      method.map(function (item) {
        return setup(path, item, handle);
      });
    } else {
      setup(path, method, handle);
    }
  });
};
export default {
  setup: setup,
  load: load,
  extend: extend
};