import Vue from 'vue';
import Vuex from 'vuex';
import admin from "./modules/admin";
import customer from "./modules/customer";
import coupon from "./modules/coupon";
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    admin: admin,
    customer: customer,
    coupon: coupon
  }
});