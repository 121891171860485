import { render, staticRenderFns } from "./menu-title.vue?vue&type=template&id=0d9d766a"
import script from "./menu-title.vue?vue&type=script&lang=js"
export * from "./menu-title.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/Baseinfo_Front_PROD_E1/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0d9d766a')) {
      api.createRecord('0d9d766a', component.options)
    } else {
      api.reload('0d9d766a', component.options)
    }
    module.hot.accept("./menu-title.vue?vue&type=template&id=0d9d766a", function () {
      api.rerender('0d9d766a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/basic-layout/menu-side/menu-title.vue"
export default component.exports