import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_PROD_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/*
 * @Description: 系统管理-菜单权限管理
 * @Author: rhd
 * @Date: 2024-09-13 18:09:32
 * @LastEditors: rhd
 * @LastEditTime: 2024-09-19 18:20:47
 */
var basePre = 'system';
var pre = 'menu';
var meta = {
  auth: true,
  closable: true,
  showMenu: false
};
export default [{
  path: "/".concat(basePre, "/").concat(pre, "/tenant-steps/:type/:id?"),
  title: '品牌管理',
  name: 'tenant-steps',
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/permission-tenant"),
    title: '品牌管理'
  }),
  component: 'pages/system/permission/tenant-steps.vue'
}, {
  path: "/".concat(basePre, "/").concat(pre, "/role-steps/:type/:id?"),
  title: '角色管理',
  name: 'role-steps',
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/permission-role"),
    title: '角色管理'
  }),
  component: 'pages/system/permission/role-steps.vue'
}, {
  path: "/".concat(basePre, "/").concat(pre, "/user-detail/:type/:id?"),
  title: '员工管理',
  name: 'user-detail',
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/permission-user"),
    title: '员工管理'
  }),
  component: 'pages/system/permission/user-detail.vue'
}];