var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-center i-not-found-tips"
  }, [_c("img", {
    staticClass: "image",
    style: {
      width: _vm.imgWidth + "px",
      height: _vm.imgHeight + "px"
    },
    attrs: {
      src: "http://image.streffy.com/StreffyAdminImage/StreffyMiddleOffice/image/defaultGraph/".concat(_vm.imgName, ".").concat(_vm.imgFormat)
    }
  }), _c("span", {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.text))])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };