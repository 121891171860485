import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_PROD_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var basePre = 'operation-center';
var pre = 'order';
var queuingPre = 'queuing';
var bookingPre = 'booking';
var preBasic = 'basic';
var preUser = 'user';
var preLoop = 'closeloop';
var preCommodity = 'commodity';
var tiktok = "tiktok-takeout";
var meta = {
  auth: true,
  closable: true
};
export default [{
  path: "/".concat(basePre, "/").concat(preLoop),
  title: '闭环管理',
  name: "".concat(preLoop),
  header: "".concat(basePre),
  // 生成的时候就是正确的 固定的
  // icon: 'md-speedometer',
  custom: 'icon-pinpai_1',
  redirect: {
    name: "".concat(preLoop, "-overview") // 动态遍历取第一子级的第一个路由name
  },
  meta: meta,
  component: 'layouts/basic-layout',
  children: [{
    path: "/".concat(basePre, "/").concat(preLoop, "/overview"),
    title: '闭环概览',
    name: "".concat(preLoop, "-overview"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '闭环概览'
    }),
    component: "pages/".concat(basePre, "/").concat(preLoop, "/overview")
  }, {
    path: "/".concat(basePre, "/").concat(preLoop, "/").concat(preLoop, "-order"),
    title: '闭环订单管理',
    name: "".concat(preLoop, "-order"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '闭环订单管理'
    }),
    component: "pages/".concat(basePre, "/").concat(preLoop, "/").concat(preLoop, "-order")
  }, {
    path: "/".concat(basePre, "/").concat(preLoop, "/").concat(preLoop, "-goods"),
    title: '商品闭环管理',
    name: "".concat(preLoop, "-goods"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '商品闭环管理'
    }),
    component: "pages/".concat(basePre, "/").concat(preLoop, "/").concat(preLoop, "-goods")
  }, {
    path: "/".concat(basePre, "/").concat(preLoop, "/concept-compensation"),
    title: '服务问题管理',
    name: "".concat(preLoop, "-concept-compensation"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '服务问题管理'
    }),
    component: "pages/".concat(basePre, "/").concat(preLoop, "/concept-compensation")
  }, {
    path: "/".concat(basePre, "/").concat(preLoop, "/customer-suggestion"),
    title: '顾客建议管理',
    name: "".concat(preLoop, "-customer-suggestion"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '顾客建议管理'
    }),
    component: "pages/".concat(basePre, "/").concat(preLoop, "/customer-suggestion")
  }, {
    path: "/".concat(basePre, "/").concat(preLoop, "/closeloopshops-configuration"),
    title: '菜品闭环管理',
    name: "".concat(preLoop, "-closeloopshops-configuration"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '菜品闭环管理'
    }),
    component: "pages/".concat(basePre, "/").concat(preLoop, "/closeloopshops-configuration")
  }, {
    path: "/".concat(basePre, "/").concat(preLoop, "/business-configuration"),
    title: '业务配置',
    name: "".concat(preLoop, "-business-configuration"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '业务配置'
    }),
    component: "pages/".concat(basePre, "/").concat(preLoop, "/business-configuration")
  }]
}, {
  path: "/".concat(basePre, "/").concat(preBasic),
  title: '基本档',
  name: "".concat(preBasic),
  header: "".concat(basePre),
  // 生成的时候就是正确的 固定的
  // icon: 'md-speedometer',
  custom: 'icon-dianpu_1',
  redirect: {
    name: "".concat(preBasic, "-company-manage") // 动态遍历取第一子级的第一个路由name
  },
  meta: meta,
  component: 'layouts/basic-layout',
  children: [{
    path: "/".concat(basePre, "/").concat(preBasic, "/company-manage"),
    title: '公司管理',
    name: "".concat(preBasic, "-company-manage"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '公司管理'
    }),
    component: "pages/".concat(basePre, "/").concat(preBasic, "/company-manage")
  },
  // {
  //     path: `/${basePre}/${preBasic}/region-manage`,
  //     title: '区域管理',
  //     name: `${preBasic}-region-manage`,
  //     meta: {
  //         ...meta,
  //         title: '区域管理'
  //     },
  //     component: `pages/${basePre}/${preBasic}/region-manage`
  // },
  {
    path: "/".concat(basePre, "/").concat(preBasic, "/store-manage"),
    title: '门店管理',
    name: "".concat(preBasic, "-store-manage"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '门店管理'
    }),
    component: "layouts/basic-layout/blank-page.vue",
    children: [{
      path: "/".concat(basePre, "/").concat(preBasic, "/store-list"),
      title: '门店管理',
      name: "".concat(preBasic, "-store-list"),
      meta: _objectSpread(_objectSpread({}, meta), {}, {
        title: '门店管理'
      }),
      component: "pages/".concat(basePre, "/").concat(preBasic, "/store-manage")
    }, {
      path: "/".concat(basePre, "/").concat(preBasic, "/store-tags-type"),
      title: '门店标签分组',
      name: "".concat(preBasic, "-store-tags-type"),
      meta: _objectSpread(_objectSpread({}, meta), {}, {
        title: '门店标签分组'
      }),
      component: "pages/".concat(basePre, "/").concat(preBasic, "/store-manage/store-tag/store-tags-type.vue")
    }, {
      path: "/".concat(basePre, "/").concat(preBasic, "/store-tags"),
      title: '门店标签',
      name: "".concat(preBasic, "-store-tags"),
      meta: _objectSpread(_objectSpread({}, meta), {}, {
        title: '门店标签'
      }),
      component: "pages/".concat(basePre, "/").concat(preBasic, "/store-manage/store-tag/store-tags.vue")
    }, {
      path: "/".concat(basePre, "/").concat(preBasic, "/store-group"),
      title: '门店分组',
      name: "".concat(preBasic, "-store-group"),
      meta: _objectSpread(_objectSpread({}, meta), {}, {
        title: '门店分组'
      }),
      component: "pages/".concat(basePre, "/").concat(preBasic, "/store-manage/store-group/store-group.vue")
    }, {
      path: "/".concat(basePre, "/").concat(preBasic, "/store-area"),
      title: '地区区域管理',
      name: "".concat(preBasic, "-store-area"),
      meta: _objectSpread(_objectSpread({}, meta), {}, {
        title: '地区区域管理'
      }),
      component: "pages/".concat(basePre, "/").concat(preBasic, "/store-manage/store-area/store-area.vue")
    }]
  }, {
    path: "/".concat(basePre, "/").concat(preBasic, "/table-manage"),
    title: '桌台管理',
    name: "".concat(preBasic, "-table-manage"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '桌台管理'
    }),
    component: "layouts/basic-layout/blank-page.vue",
    children: [{
      path: "/".concat(basePre, "/").concat(preBasic, "/table-list"),
      title: '桌台列表',
      name: "".concat(preBasic, "-table-list"),
      meta: _objectSpread(_objectSpread({}, meta), {}, {
        title: '桌台列表'
      }),
      component: "pages/".concat(basePre, "/").concat(preBasic, "/table-manage")
    }, {
      path: "/".concat(basePre, "/").concat(preBasic, "/table-template"),
      title: '桌台类型管理',
      name: "".concat(preBasic, "-table-template"),
      meta: _objectSpread(_objectSpread({}, meta), {}, {
        title: '桌台类型管理'
      }),
      component: "pages/".concat(basePre, "/").concat(preBasic, "/table-manage/table-template/table-template.vue")
    },
    // {
    //     path: `/${basePre}/${preBasic}/table-tags-type`,
    //     title: '桌台标签分组',
    //     name: `${preBasic}-table-tags-type`,
    //     meta: {
    //         ...meta,
    //         title: '桌台标签分组'
    //     },
    //     component: `pages/${basePre}/${preBasic}/table-manage/table-tag/table-tags-type.vue`
    // },
    // {
    //     path: `/${basePre}/${preBasic}/table-tags`,
    //     title: '桌台标签',
    //     name: `${preBasic}-table-tags`,
    //     meta: {
    //         ...meta,
    //         title: '桌台标签'
    //     },
    //     component: `pages/${basePre}/${preBasic}/table-manage/table-tag/table-tags.vue`
    // },
    {
      path: "/".concat(basePre, "/").concat(preBasic, "/table-area"),
      title: '桌台区域管理',
      name: "".concat(preBasic, "-table-area"),
      meta: _objectSpread(_objectSpread({}, meta), {}, {
        title: '桌台区域管理'
      }),
      component: "pages/".concat(basePre, "/").concat(preBasic, "/table-manage/table-area/table-area.vue")
    }]
  }, {
    path: "/".concat(basePre, "/").concat(preBasic, "/meal-period"),
    title: '餐段模版管理',
    name: "".concat(preBasic, "-meal-period"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '餐段模版管理'
    }),
    component: "pages/".concat(basePre, "/").concat(preBasic, "/meal-period")
  }, {
    path: "/".concat(basePre, "/").concat(preBasic, "/operation-manage"),
    title: '业务开关与配置',
    name: "".concat(preBasic, "-operation-manage"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '业务开关与配置'
    }),
    component: "pages/".concat(basePre, "/").concat(preBasic, "/operation-manage")
  }]
}, {
  path: "/".concat(basePre, "/").concat(preUser),
  title: '人员管理',
  name: "".concat(preUser),
  header: "".concat(basePre),
  // 生成的时候就是正确的 固定的
  // icon: 'md-speedometer',
  custom: 'icon-kehuguanli2_1',
  redirect: {
    name: "".concat(preUser, "-roles-manage") // 动态遍历取第一子级的第一个路由name
  },
  meta: meta,
  component: 'layouts/basic-layout',
  children: [{
    path: "/".concat(basePre, "/").concat(preUser, "/roles-manage"),
    title: '角色管理',
    name: "".concat(preUser, "-roles-manage"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '角色管理'
    }),
    component: "pages/".concat(basePre, "/").concat(preUser, "/roles-manage")
  }, {
    path: "/".concat(basePre, "/").concat(preUser, "/employee-manage"),
    title: '员工管理',
    name: "".concat(preUser, "-employee-manage"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '员工管理'
    }),
    component: "pages/".concat(basePre, "/").concat(preUser, "/employee-manage")
  }, {
    path: "/".concat(basePre, "/").concat(preUser, "/system-roles-manage"),
    title: '系统角色管理',
    name: "".concat(preUser, "-system-roles-manage"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '系统角色管理'
    }),
    component: "pages/".concat(basePre, "/").concat(preUser, "/system-roles-manage")
  }
  // {
  //     path: `/${basePre}/${preUser}/user-manage`,
  //     title: '用户管理',
  //     name: `${preUser}-user-manage`,
  //     meta: {
  //         ...meta,
  //         title: '用户管理'
  //     },
  //     component: `pages/${basePre}/${preUser}/user-manage`
  // }
  ]
}, {
  path: "/".concat(basePre, "/").concat(pre),
  title: '订单管理',
  name: "".concat(pre),
  header: "".concat(basePre),
  // 生成的时候就是正确的 固定的
  // icon: 'md-speedometer',
  custom: 'icon-yingxiaozhongxin2',
  redirect: {
    name: "".concat(pre, "-menu-order") // 动态遍历取第一子级的第一个路由name
  },
  meta: meta,
  component: 'layouts/basic-layout',
  children: [
  // {
  //     path: `/${basePre}/${pre}/business-overview`,
  //     title: '营业概况',
  //     name: `${pre}-business-overview`,
  //     meta: {
  //         ...meta,
  //         title: '营业概况'
  //     },
  //     component: `pages/${basePre}/${pre}/business-overview`
  // },
  {
    path: "/".concat(basePre, "/").concat(pre, "/menu-order"),
    title: '点餐订单',
    name: "".concat(pre, "-menu-order"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '点餐订单'
    }),
    component: "pages/".concat(basePre, "/").concat(pre, "/menu-order")
  }
  // {
  //     path: `/${basePre}/${pre}/coupon-order`,
  //     title: '团购券订单',
  //     name: `${pre}-coupon-order`,
  //     meta: {
  //         ...meta,
  //         title: '团购券订单'
  //     },
  //     component: `pages/${basePre}/${pre}/coupon-order`
  // },
  // {
  //     path: `/${basePre}/${pre}/integral-order`,
  //     title: '积分商城订单',
  //     name: `${pre}-integral-order`,
  //     meta: {
  //         ...meta,
  //         title: '积分商城订单'
  //     },
  //     component: `pages/${basePre}/${pre}/integral-order`
  // },
  // {
  //     path: `/${basePre}/${pre}/coupon-package-order`,
  //     title: '券包订单',
  //     name: `${pre}-coupon-package-order`,
  //     meta: {
  //         ...meta,
  //         title: '券包订单'
  //     },
  //     component: `pages/${basePre}/${pre}/coupon-package-order`
  // },
  // {
  //     path: `/${basePre}/${pre}/recharge-order`,
  //     title: '储值订单',
  //     name: `${pre}-recharge-order`,
  //     meta: {
  //         ...meta,
  //         title: '储值订单'
  //     },
  //     component: `pages/${basePre}/${pre}/recharge-order`
  // }
  ]
}, {
  path: "/".concat(basePre, "/").concat(preCommodity),
  title: '商品管理',
  name: "".concat(preCommodity),
  custom: 'icon-pinpai_1',
  header: "".concat(basePre),
  // 生成的时候就是正确的 固定的
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '商品管理'
  }),
  component: 'layouts/basic-layout',
  children: [{
    path: "/".concat(basePre, "/").concat(preCommodity, "/category"),
    title: '大类管理',
    name: "".concat(preCommodity, "-category"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '大类管理'
    }),
    component: "pages/".concat(basePre, "/").concat(preCommodity, "/category")
  }, {
    path: "/".concat(basePre, "/").concat(preCommodity, "/classified"),
    title: '商品分类管理',
    name: "".concat(preCommodity, "-classified"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '商品分类管理'
    }),
    component: "pages/".concat(basePre, "/").concat(preCommodity, "/classified")
  }, {
    path: "/".concat(basePre, "/").concat(preCommodity, "/secondary-classification"),
    title: '商品二级分类管理',
    name: "".concat(preCommodity, "-secondary-classification"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '商品二级分类管理'
    }),
    component: "pages/".concat(basePre, "/").concat(preCommodity, "/secondary-classification")
  }, {
    path: "/".concat(basePre, "/").concat(preCommodity, "/attribute"),
    title: '属性管理',
    name: "".concat(preCommodity, "-attribute"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '属性管理'
    }),
    component: "layouts/basic-layout/blank-page.vue",
    children: [{
      path: "/".concat(basePre, "/").concat(preCommodity, "/attribute/single"),
      title: '属性管理',
      name: "".concat(preCommodity, "-single"),
      meta: _objectSpread(_objectSpread({}, meta), {}, {
        title: '属性管理'
      }),
      component: "pages/".concat(basePre, "/").concat(preCommodity, "/attribute/single")
    }, {
      path: "/".concat(basePre, "/").concat(preCommodity, "/attribute/group"),
      title: '属性分组管理',
      name: "".concat(preCommodity, "-group"),
      meta: _objectSpread(_objectSpread({}, meta), {}, {
        title: '属性分组管理'
      }),
      component: "pages/".concat(basePre, "/").concat(preCommodity, "/attribute/group")
    }]
  }, {
    path: "/".concat(basePre, "/").concat(preCommodity, "/packageitem-group"),
    title: '套餐子项分组管理',
    name: "".concat(preCommodity, "-packageitem-group"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '套餐子项分组管理'
    }),
    component: "pages/".concat(basePre, "/").concat(preCommodity, "/packageitem-group")
  }, {
    path: "/".concat(basePre, "/").concat(preCommodity, "/sales-unit"),
    title: '销售单位管理',
    name: "".concat(preCommodity, "-sales-unit"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '销售单位管理'
    }),
    component: "pages/".concat(basePre, "/").concat(preCommodity, "/sales-unit")
  }, {
    path: "/".concat(basePre, "/").concat(preCommodity, "/merchandise-marks"),
    title: '商品标记管理',
    name: "".concat(preCommodity, "-merchandise-marks"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '商品标记管理'
    }),
    component: "pages/".concat(basePre, "/").concat(preCommodity, "/merchandise-marks")
  }, {
    path: "/".concat(basePre, "/").concat(preCommodity, "/dining-management"),
    title: '菜品管理',
    name: "".concat(preCommodity, "-dining-management"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '菜品管理'
    }),
    component: "layouts/basic-layout/blank-page.vue",
    children: [{
      path: "/".concat(basePre, "/").concat(preCommodity, "/dining-management/dining"),
      title: '菜品管理',
      name: "".concat(preCommodity, "-dining"),
      meta: _objectSpread(_objectSpread({}, meta), {}, {
        title: '菜品管理'
      }),
      component: "pages/".concat(basePre, "/").concat(preCommodity, "/dining-management/dining")
    }]
  }, {
    path: "/".concat(basePre, "/").concat(preCommodity, "/menu-manage"),
    title: '菜谱管理',
    name: "".concat(preCommodity, "-menu-manage"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '菜谱管理'
    }),
    component: "layouts/basic-layout/blank-page.vue",
    children: [{
      path: "/".concat(basePre, "/").concat(preCommodity, "/menu"),
      title: '菜谱管理',
      name: "".concat(preCommodity, "-menu"),
      meta: _objectSpread(_objectSpread({}, meta), {}, {
        title: '菜谱管理'
      }),
      component: "pages/".concat(basePre, "/").concat(preCommodity, "/menu")
    }, {
      path: "/".concat(basePre, "/").concat(preCommodity, "/menu/publish-records"),
      title: '发布记录',
      name: "".concat(preCommodity, "-menu-publish-records"),
      meta: _objectSpread(_objectSpread({}, meta), {}, {
        title: '发布记录'
      }),
      component: "pages/".concat(basePre, "/").concat(preCommodity, "/menu/publish-records.vue")
    }]
  }]
}, {
  path: "/".concat(basePre, "/").concat(bookingPre),
  title: '预订管理',
  name: "".concat(bookingPre),
  header: "".concat(basePre),
  // 生成的时候就是正确的 固定的
  // icon: 'md-speedometer',
  custom: 'icon-huiyuanyunying2_1',
  redirect: {
    name: "".concat(bookingPre, "-overview") // 动态遍历取第一子级的第一个路由name
  },
  meta: meta,
  component: 'layouts/basic-layout',
  children: [{
    path: "/".concat(basePre, "/").concat(bookingPre, "/overview"),
    title: '预订概览',
    name: "".concat(bookingPre, "-overview"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '预订概览'
    }),
    component: "pages/".concat(basePre, "/").concat(bookingPre, "/overview")
  }, {
    path: "/".concat(basePre, "/").concat(bookingPre, "/setting"),
    title: '预订设置',
    name: "".concat(bookingPre, "-setting"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '预订设置'
    }),
    component: "pages/".concat(basePre, "/").concat(bookingPre, "/setting")
  }, {
    path: "/".concat(basePre, "/").concat(bookingPre, "/list"),
    title: '预订订单',
    name: "".concat(bookingPre, "-order"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '预订订单'
    }),
    component: "pages/".concat(basePre, "/").concat(bookingPre, "/order")
  }, {
    path: "/".concat(basePre, "/").concat(bookingPre, "/block"),
    title: '爽约名单',
    name: "".concat(bookingPre, "-block"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '爽约名单'
    }),
    component: "pages/".concat(basePre, "/").concat(bookingPre, "/block")
  }]
}, {
  path: "/".concat(basePre, "/").concat(queuingPre),
  title: '排队叫号管理',
  name: "".concat(queuingPre),
  header: "".concat(basePre),
  // 生成的时候就是正确的 固定的
  // icon: 'md-speedometer',
  custom: 'icon-youxiaoqi_1',
  redirect: {
    name: "".concat(queuingPre, "-overview") // 动态遍历取第一子级的第一个路由name
  },
  meta: meta,
  component: 'layouts/basic-layout',
  children: [{
    path: "/".concat(basePre, "/").concat(queuingPre, "/overview"),
    title: '排队叫号概览',
    name: "".concat(queuingPre, "-overview"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '排队叫号概览'
    }),
    component: "pages/".concat(basePre, "/").concat(queuingPre, "/overview")
  }, {
    path: "/".concat(basePre, "/").concat(queuingPre, "/setting"),
    title: '排队叫号设置',
    name: "".concat(queuingPre, "-setting"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '排队叫号设置'
    }),
    component: "pages/".concat(basePre, "/").concat(queuingPre, "/setting")
  }, {
    path: "/".concat(basePre, "/").concat(queuingPre, "/list"),
    title: '排队叫号订单',
    name: "".concat(queuingPre, "-order"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '排队叫号订单'
    }),
    component: "pages/".concat(basePre, "/").concat(queuingPre, "/order")
  }, {
    path: "/".concat(basePre, "/").concat(queuingPre, "/block"),
    title: '爽约名单',
    name: "".concat(queuingPre, "-block"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '爽约名单'
    }),
    component: "pages/".concat(basePre, "/").concat(queuingPre, "/block")
  }]
}, {
  path: "/".concat(basePre, "/").concat(tiktok),
  title: '抖音外卖业务',
  name: "".concat(tiktok),
  header: "".concat(basePre),
  // 生成的时候就是正确的 固定的
  // icon: 'md-speedometer',
  custom: 'icon-youxiaoqi_1',
  redirect: {
    name: "".concat(tiktok, "-overview") // 动态遍历取第一子级的第一个路由name
  },
  meta: meta,
  component: 'layouts/basic-layout',
  children: [{
    path: "/".concat(basePre, "/").concat(tiktok, "/matching"),
    title: '门店poi匹配',
    name: "".concat(tiktok, "-matching"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '门店poi匹配'
    }),
    component: "pages/".concat(basePre, "/").concat(tiktok, "/matching")
  }, {
    path: "/".concat(basePre, "/").concat(tiktok, "/synchronization"),
    title: '门店同步',
    name: "".concat(tiktok, "-synchronization"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '门店同步'
    }),
    component: "pages/".concat(basePre, "/").concat(tiktok, "/synchronization")
  }, {
    path: "/".concat(basePre, "/").concat(tiktok, "/product"),
    title: '商品同步',
    name: "".concat(tiktok, "-product"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '商品同步'
    }),
    component: "pages/".concat(basePre, "/").concat(tiktok, "/product")
  }, {
    path: "/".concat(basePre, "/").concat(tiktok, "/bill"),
    title: '抖音对账',
    name: "".concat(tiktok, "-bill"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '抖音对账'
    }),
    component: "pages/".concat(basePre, "/").concat(tiktok, "/bill")
  }]
}];