import "core-js/modules/es6.number.constructor";
export default {
  name: 'NotFoundTips',
  props: {
    imgName: {
      type: String,
      required: false,
      default: 'nodata'
    },
    imgFormat: {
      type: String,
      required: false,
      default: 'png'
    },
    imgWidth: {
      type: [String, Number],
      required: false,
      default: '200'
    },
    imgHeight: {
      type: [String, Number],
      required: false,
      default: '200'
    },
    text: {
      type: String,
      required: false,
      default: '暂无数据'
    }
  },
  data: function data() {
    return {};
  }
};