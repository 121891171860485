import Cookies from 'js-cookie';
import Setting from '@/setting';
var cookies = {};

/**
 * @description 存储 cookie 值
 * @param {String} name cookie name
 * @param {String} value cookie value
 * @param {Object} cookieSetting cookie setting
 * @param {Boolean} ifDefName cookie ifDefName
 */
cookies.set = function () {
  var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';
  var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var cookieSetting = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var ifDefName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  if (!ifDefName) {
    // 防止数字类型数据写入缓存时，浏览器异常读取为长度导致失败，将value强转为字符串格式
    value = 'uuid-' + value;
  }
  var currentCookieSetting = {
    expires: Setting.cookiesExpires
  };
  Object.assign(currentCookieSetting, cookieSetting);
  Cookies.set("streffy-admin-".concat(name), value, currentCookieSetting);
};

/**
 * @description 拿到 cookie 值
 * @param {String} name cookie name
 */
cookies.get = function () {
  var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';
  return Cookies.get("streffy-admin-".concat(name));
};

/**
 * @description 拿到 cookie 全部的值
 */
cookies.getAll = function () {
  return Cookies.get();
};

/**
 * @description 删除 cookie
 * @param {String} name cookie name
 */
cookies.remove = function () {
  var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';
  return Cookies.remove("streffy-admin-".concat(name));
};
export default cookies;