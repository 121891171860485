import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_PROD_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var basePre = 'marketing-center';
var pre = 'evaluate';
var meta = {
  auth: true,
  closable: true
};
export default [{
  path: "/".concat(basePre),
  title: '营销活动',
  name: "".concat(basePre),
  header: "".concat(basePre),
  // 生成的时候就是正确的 固定的
  icon: 'md-speedometer',
  redirect: {
    name: "".concat(basePre, "-gift-index") // 动态遍历取第一子级的第一个路由name
  },
  meta: meta,
  component: 'layouts/basic-layout',
  children: [{
    path: "/".concat(basePre, "/gift-index"),
    title: '营销活动管理面板',
    name: "".concat(basePre, "-gift-index"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '营销活动管理面板'
    }),
    component: "pages/".concat(basePre, "/gift-index/index")
  }, {
    path: "/".concat(basePre, "/evaluate-courtesy"),
    title: '评价有礼',
    name: "".concat(pre, "-courtesy"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '评价有礼'
    }),
    component: "pages/".concat(basePre, "/evaluate/evaluate-courtesy/index")
  }, {
    path: "/".concat(basePre, "/gift-alert"),
    title: '弹屏有礼',
    name: "gift-alert",
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '弹屏有礼'
    }),
    component: "pages/".concat(basePre, "/gift-alert")
  }, {
    path: "/".concat(basePre, "/gift-password"),
    title: '口令领券',
    name: "gift-password",
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '口令领券'
    }),
    component: "pages/".concat(basePre, "/gift-password")
  }, {
    path: "/".concat(basePre, "/gift-sign"),
    title: '签到有礼',
    name: "gift-sign",
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '签到有礼'
    }),
    component: "pages/".concat(basePre, "/gift-sign")
  }, {
    path: "/".concat(basePre, "/gift-consumption"),
    title: '消费送礼',
    name: "gift-consumption",
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '消费送礼'
    }),
    component: "pages/".concat(basePre, "/gift-consumption")
  }, {
    path: "/".concat(basePre, "/gift-getfree"),
    title: '免费领取',
    name: "gift-getfree",
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '免费领取'
    }),
    component: "pages/".concat(basePre, "/gift-getfree")
  }, {
    path: "/".concat(basePre, "/gift-integral"),
    title: '消费返积分',
    name: "gift-integral",
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '消费返积分'
    }),
    component: "pages/".concat(basePre, "/gift-integral")
  }, {
    path: "/".concat(basePre, "/gift-raffle"),
    title: '摇奖活动',
    name: "gift-integral",
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '摇奖活动'
    }),
    component: "pages/".concat(basePre, "/gift-raffle")
  }, {
    path: "/".concat(basePre, "/gift-perfect"),
    title: '完善资料送礼',
    name: "gift-perfect",
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '完善资料送礼'
    }),
    component: "pages/".concat(basePre, "/gift-perfect")
  }, {
    path: "/".concat(basePre, "/gift-birthday"),
    title: '生日赠送',
    name: "gift-birthday",
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '生日赠送'
    }),
    component: "pages/".concat(basePre, "/gift-birthday")
  }, {
    path: "/".concat(basePre, "/gift-awaken"),
    title: '唤醒有礼',
    name: "gift-awaken",
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '唤醒有礼'
    }),
    component: "pages/".concat(basePre, "/gift-awaken")
  }, {
    path: "/".concat(basePre, "/gift-gather"),
    title: '集点活动',
    name: "gift-gather",
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '集点活动'
    }),
    component: "pages/".concat(basePre, "/gift-gather")
  }]
}];