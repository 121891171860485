var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("Button", {
    staticClass: "i-ml-10",
    attrs: {
      type: "primary",
      ghost: "",
      icon: "md-cloud-download",
      size: "large"
    },
    on: {
      click: _vm.handleDownload
    }
  }, [_vm._v("\n    导出\n")]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };