import "core-js/modules/es6.array.find";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.array.filter";
import "core-js/modules/es6.date.to-iso-string";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.date.to-json";
import dayjs from "dayjs";
import util from '@/libs/util';

// 时间过滤器
var formatTime = function formatTime(time) {
  if (time) {
    var a = new Date(time).toJSON();
    var date = new Date(+new Date(a) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');
    return date;
  }
};

// 时间格式过滤器
var formatDateTimeType = function formatDateTimeType(date) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY-MM-DD';
  if (date && date !== '0001-01-01 00:00:00') {
    return dayjs(date).format(type);
  } else {
    return '-';
  }
};

// 空数据过滤器
var formatValue = function formatValue(value) {
  if (value) {
    if (value === 'NaN' || value === 'null') {
      return '--';
    }
    return value;
  } else {
    if (value === 0) {
      return 0;
    }
    return '--';
  }
};

// 来源渠道-线上/线下数据过滤器
var formatTypeFrom = function formatTypeFrom(value) {
  if (!value) {
    return '--';
  }
  var data = util.TYPE_FROM_LIST.filter(function (data) {
    return data.value === Number(value);
  });
  if (data && data.length) {
    return data[0] && data[0].label ? data[0].label : '--';
  } else {
    return '--';
  }
};
// 性别数据过滤器
var formatSex = function formatSex(value) {
  if (!value && value !== 0) {
    return '--';
  }
  var data = util.SEX_LIST.filter(function (data) {
    return data.value === Number(value);
  });
  if (data && data.length) {
    return data[0] && data[0].label ? data[0].label : '--';
  } else {
    return '--';
  }
};

// 订单来源数据过滤器
var formatOrderSource = function formatOrderSource(value) {
  if (!value) {
    return '--';
  }
  var data = util.BOOKING_SOURCE.filter(function (data) {
    return data.value === value;
  });
  if (data && data.length) {
    return data[0] && data[0].label ? data[0].label : '--';
  } else {
    return '--';
  }
};

// 来源平台-用户来源数据过滤器
var formatUserSourceFrom = function formatUserSourceFrom(value) {
  if (!value) {
    return '--';
  }
  var data = util.SOURCE_FROM_LIST.filter(function (data) {
    return data.value === '' + value;
  });
  if (data && data.length) {
    return data[0] && data[0].label ? data[0].label : '--';
  } else {
    return '--';
  }
};

// 品牌名称显示
var tenantNameById = function tenantNameById(value) {
  if (util.TENANTID_LIST && util.TENANTID_LIST.length && util.TENANTID_LIST.find(function (v) {
    return v.value === value;
  })) {
    return util.TENANTID_LIST.find(function (v) {
      return v.value === value;
    }).label;
  } else {
    return value;
  }
};
export { formatTime, formatDateTimeType, formatValue, formatTypeFrom, formatSex, formatOrderSource, formatUserSourceFrom, tenantNameById };