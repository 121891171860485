import "core-js/modules/es6.regexp.replace";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("MenuItem", {
    staticClass: "i-layout-menu-side-item",
    attrs: {
      to: _vm.menu.path,
      replace: _vm.menu.replace,
      target: _vm.menu.target,
      name: _vm.menu.path
    },
    nativeOn: {
      click: function click($event) {
        return _vm.handleClick(_vm.menu.path);
      }
    }
  }, [_c("i-menu-side-title", {
    attrs: {
      menu: _vm.menu,
      "hide-title": _vm.hideTitle
    }
  }), _vm.badgeData ? _c("Badge", _vm._b({
    staticClass: "i-layout-menu-side-badge"
  }, "Badge", _vm.badgeData, false)) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };