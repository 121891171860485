import "core-js/modules/es6.typed.uint8-array";
import XLSX from 'xlsx'; //

export default {
  props: {
    action: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleFileUpload: function handleFileUpload(event) {
      var file = event.target.files[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, {
          type: 'array'
        });

        // 假设我们只处理第一个sheet
        var sheetName = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[sheetName];

        // 将worksheet转换为JSON
        var jsonData = XLSX.utils.sheet_to_json(worksheet, {
          header: 1
        });

        // 处理jsonData，例如更新表格数据等
        console.log(jsonData);
      };
      reader.readAsArrayBuffer(file);
    }
  }
};