// components/Loading/loading.js

/* 组件分为全局注册,局部注册,通过js的方式去注册(这种组件只适用于消息提示,loading展示,成功的展示) */
import Loading from "./index.vue";
export default {
  install: function install(Vue, options) {
    Vue.prototype.$loading = {
      _component: '',
      show: function show() {
        var LoadingClass = Vue.extend(Loading); /* 生成组件类 */
        this._component = new LoadingClass(); /* 实例化组件 */
        this._wrap = document.createElement('div');
        document.body.appendChild(this._wrap); /* 把这个dom放到body里面去 */
        this._component.$mount(this._wrap); /* 执行渲染 */
      },
      hide: function hide() {
        this._component.$destroy();
      }
    };
  }
};