/*
 * @Description: 权限管理
 * @Author: rhd
 * @Date: 2024-09-25 18:20:42
 * @LastEditors: rhd
 * @LastEditTime: 2024-10-10 17:59:25
 */
import request from '@/plugins/request';
var proxy = process.env.VUE_APP_API;
export function AccountLogin(data) {
  return request({
    url: '/api/login',
    method: 'post',
    data: data,
    noNeedToken: true
  });
}
export function GetTicket(data) {
  return request({
    url: proxy + '/api/auth/ticket/get',
    method: 'post',
    data: data,
    noNeedToken: true,
    notNeedTenant: true
  });
}
export function AccountRegister(data) {
  return request({
    url: '/api/register',
    method: 'post',
    data: data
  });
}

// export function GetHeaderMenu (data) {
//     return request({
//         url: '/api/getHeaderMenu',
//         method: 'post',
//         data
//     });
// }

// export function GetSiderMenu (data) {
//     return request({
//         url: '/api/getSiderMenu',
//         method: 'post',
//         data
//     });
// }

export function GetHeaderMenu(data) {
  return request({
    url: proxy + '/power/v2/menu/getbyuser',
    method: 'post',
    data: data
  });
}
export function GetSystemmenu(data) {
  return request({
    url: proxy + '/power/v2/systemmenu/get',
    method: 'post',
    data: data
  });
}
export function SaveSystemmenu(data) {
  return request({
    url: proxy + '/power/v2/systemmenu/save',
    method: 'post',
    data: data
  });
}

// 获取品牌列表
export function GetTenantList(data) {
  return request({
    url: proxy + '/power/v2/tenant/list',
    method: 'post',
    data: data,
    notNeedTenant: true
  });
}
// 创建品牌
export function SaveTenant(data) {
  return request({
    url: proxy + '/power/v1/tenant/create',
    method: 'post',
    data: data,
    notNeedTenant: true
  });
}

// 获取品牌信息
export function GetTenant(data) {
  return request({
    url: proxy + '/power/v1/tenant/get',
    method: 'post',
    data: data,
    notNeedTenant: true
  });
}

// 修改品牌状态
export function ChangeTenantStatus(data) {
  return request({
    url: proxy + '/power/v1/tenant/status/change',
    method: 'post',
    data: data,
    notNeedTenant: true
  });
}

// 获取商户菜单权限
export function GetMenuByTenant(data) {
  return request({
    url: proxy + '/power/v2/systemmenu/tenant',
    method: 'post',
    data: data,
    notNeedTenant: true
  });
}

// 保存商户菜单
export function SaveMenuByTenant(data) {
  return request({
    url: proxy + '/power/v2/systemmenu/tenant/save',
    method: 'post',
    data: data,
    notNeedTenant: true
  });
}

// 获取系统角色列表
export function GetRoleList(data) {
  return request({
    url: proxy + '/power/v2/role/list',
    method: 'post',
    data: data,
    notNeedTenant: true
  });
}

// 修改角色状态
export function ChangeRoleStatus(data) {
  return request({
    url: proxy + '/power/v2/role/status/change',
    method: 'post',
    data: data,
    notNeedTenant: true
  });
}

// 获取角色菜单权限
export function GetMenuByRole(data) {
  return request({
    url: proxy + '/power/v2/role/map/menu/get',
    method: 'post',
    data: data,
    notNeedTenant: true
  });
}

// 保存角色菜单权限
export function SaveMenuByRole(data) {
  return request({
    url: proxy + '/power/v2/role/map/menu/save',
    method: 'post',
    data: data,
    notNeedTenant: true
  });
}

// 保存角色
export function SaveRole(data) {
  return request({
    url: proxy + '/power/v2/role/save',
    method: 'post',
    data: data,
    notNeedTenant: true
  });
}

// 获取角色
export function GetRoleInfo(data) {
  return request({
    url: proxy + '/power/v2/role/info',
    method: 'post',
    data: data,
    notNeedTenant: true
  });
}

// 获取用户列表
export function GetUserList(data) {
  return request({
    url: proxy + '/power/v2/user/list',
    method: 'post',
    data: data,
    notNeedTenant: true
  });
}

// 修改用户状态
export function ChangeUserStatus(data) {
  return request({
    url: proxy + '/power/v2/user/status/change',
    method: 'post',
    data: data,
    notNeedTenant: true
  });
}

// 保存用户
export function SaveUser(data) {
  return request({
    url: proxy + '/power/v2/save',
    method: 'post',
    data: data,
    notNeedTenant: true
  });
}

// 获取用户信息
export function GetUserInfo(data, head) {
  return request({
    url: proxy + '/power/v2/user/info',
    method: 'post',
    data: data,
    notNeedTenant: true
  });
}

// 获取当前用户角色
export function GetUserRoles(data) {
  return request({
    url: proxy + '/power/v2/user/roles/get',
    method: 'post',
    data: data,
    notNeedTenant: true
  });
}

// 赋予角色
export function SaveUserRole(data) {
  return request({
    url: proxy + '/power/v2/user/roles/assign',
    method: 'post',
    data: data,
    notNeedTenant: true
  });
}