import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_PROD_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.array.find";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// import menuHeader from '@/menu/header';
// import menuSider from '@/menu/sider';

var userDB = [{
  username: 'admin',
  password: 'admin',
  uuid: 'streffy-admin-uuid',
  info: {
    name: 'Aresn',
    avatar: 'https://dev-file.iviewui.com/userinfoPDvn9gKWYihR24SpgC319vXY8qniCqj4/avatar',
    access: ['admin']
  }
}];
export default [{
  path: '/api/login',
  method: 'post',
  handle: function handle(_ref) {
    var body = _ref.body;
    var user = userDB.find(function (e) {
      return e.username === body.username && e.password === body.password;
    });
    if (user) {
      return {
        code: 200,
        msg: '登录成功',
        data: _objectSpread(_objectSpread({}, user), {}, {
          token: 'A68NUPaXVBJYRStwvd9frcUn8rlf30h6'
        })
      };
    } else {
      return {
        code: 401,
        msg: '用户名或密码错误',
        data: {}
      };
    }
  }
}, {
  path: '/api/register',
  method: 'post',
  handle: function handle(_ref2) {
    var body = _ref2.body;
    return {
      code: 200,
      msg: '注册成功',
      data: {
        username: 'admin',
        uuid: 'streffy-admin-uuid',
        info: {
          name: 'Aresn',
          avatar: 'https://dev-file.iviewui.com/userinfoPDvn9gKWYihR24SpgC319vXY8qniCqj4/avatar',
          access: ['admin']
        },
        token: 'A68NUPaXVBJYRStwvd9frcUn8rlf30h6'
      }
    };
  }
}
// {
//     path: '/api/getHeaderMenu',
//     method: 'post',
//     handle ({ body }) {
//         return {
//             code: 200,
//             msg: '成功',
//             data: menuHeader
//         }
//     }
// },
// {
//     path: '/api/getSiderMenu',
//     method: 'post',
//     handle ({ body }) {
//         const menu = menuSider.filter(v => body.names.includes(v.header))
//         if (menu) {
//             return {
//                 code: 200,
//                 msg: '成功',
//                 data: menu
//             }
//         } else {
//             return {
//                 code: 400,
//                 msg: '失败',
//                 data: null
//             }
//         }
//     }
// }
];